const Picture = props => {
    const { src = '' } = props
    const ext = src.substr(src.lastIndexOf('.') + 1)
    const webPSrc = src.substr(0, src.lastIndexOf('.')) + '.webp'

    return (
        <picture>
            { ext !== 'svg' && <source srcSet={ webPSrc } type="image/webp" /> }
            <img { ...props } />
        </picture>
    )
}

export default Picture